import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {LazyImageBackground} from '@/components/basic/image';
import React from 'react';
import Text from '@/components/basic/text';
import {StyleSheet, View, Image} from 'react-native';
import theme from '@/style';
import {KeralaTicket} from '../result.type';
import dayjs from 'dayjs';
import {goTo} from '@/utils';
const down = require('@components/assets/icons/down.webp');
const ListItem = ({issueNo, lotteryType, drawTime, drawDate}: KeralaTicket) => {
  return (
    <NativeTouchableOpacity
      style={[theme.flex.row]}
      onPress={() =>
        goTo('KeralaResultDetail', {
          issueNo,
          drawDate,
        })
      }>
      <LazyImageBackground
        width={130}
        height={68}
        style={[theme.flex.centerByRow]}
        imageUrl={require('@/components/assets/imgs/result/ticket-left.kerala.webp')}>
        <Text
          style={[theme.margin.leftxxl]}
          main
          fontFamily="fontDinBold"
          size="large">
          {issueNo}
        </Text>
      </LazyImageBackground>
      <View
        style={[
          theme.flex.flex1,
          theme.flex.row,
          theme.flex.centerByCol,
          theme.background.white,
          styles.right,
          theme.padding.lrxxl,
          theme.flex.between,
        ]}>
        <View>
          <Text main fontFamily="fontDinBold" size="medium">
            {lotteryType}
          </Text>
          <Text
            accent
            style={[
              // eslint-disable-next-line react-native/no-inline-styles
              {
                fontWeight: '500',
                marginTop: 4,
              },
            ]}>
            {dayjs(drawDate).format('DD-MM-YYYY')} {drawTime}
          </Text>
        </View>
        <View
          style={[
            {
              transform: [
                {
                  rotate: '-90deg',
                },
              ],
            },
          ]}>
          <Image
            source={down}
            style={[{width: theme.iconSize.xs, height: theme.iconSize.xs}]}
          />
        </View>
      </View>
    </NativeTouchableOpacity>
  );
};

const styles = StyleSheet.create({
  right: {
    borderWidth: 3,
    borderColor: '#FFE454',
    borderLeftWidth: 0,
  },
});

export default ListItem;
